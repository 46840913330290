<template>
  <v-card flat>
    <v-container grid-list-xl>
      <v-row>
        <v-col
          v-for="(item, i) in items"
          :key="i"
          cols="4"
          class="d-flex align-stretch"
        >
          <v-card width="100%">
            <swiper
              v-if="items.filter(x => x.brands.length > 0).length"
              ref="swiperTwoRow"
              :options="swiperOptions"
              style="height: 4em;"
            >
              <swiper-slide
                v-for="(brand, b) in item.brands"
                :key="b"
              >
                <v-card
                  flat
                  class="my-1"
                >
                  <v-img
                    width="3.75em"
                    aspect-ratio="1"
                    :src="brand.logo | mImage"
                    contain
                    class="mx-auto"
                  />
                </v-card>
              </swiper-slide>
            </swiper>
            <v-card-title class="grey--text font-weight-bold px-2" style="word-break: break-word; height: 4em; font-size: 1.3em;">
              {{ item.title }}
            </v-card-title>
            <v-img
              :src="item.photo | mImage"
              height="12em"
            >
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate />
                </v-row>
              </template>
            </v-img>
            <v-card-text class="flex-column align-start pa-2 body-2 black--text">
              <div class="font-weight-bold body-1">
                {{ item.title }}
              </div>
              <div class="body-1 pb-1" style="white-space: pre;" v-text="item.address" />
              <div v-if="item.phone">
                <v-icon left>
                  mdi-phone
                </v-icon>
                {{ item.phone }}
              </div>
              <div v-if="item.fax">
                <v-icon left>
                  mdi-fax
                </v-icon>
                {{ item.fax }}
              </div>
              <div v-if="item.email">
                <v-icon left>
                  mdi-email
                </v-icon>
                {{ item.email }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'MLocationCards',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    swiperOptions: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      centerInsufficientSlides: true,
      spaceBetween: 0,
      touchRatio: 1,
      autoplay: {
        delay: 3000
      }
    }
  }),
  mounted() {
    this.$emit('loaded')
  }
}
</script>
